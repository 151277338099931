import React, { useState, useEffect, useRef } from "react"
import { v4 as uuidv4 } from "uuid"

import { getUser } from "../utils/auth"
import { API_URL } from "../utils/helpers"

import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"

import Nav from "../components/Nav/index"

import Alert from "../components/Alert/index"

import Input from "../components/form/Input"
import Msg from "../components/form/Msg"
import Submit from "../components/form/Submit"

import { Container, Grid } from "../components/donation-reports/styled.js"

import Table from "../components/donation-reports/Table/index"

const Donations = () => {
  const [items, setItems] = useState([])
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const initRef = useRef(false)

  useEffect(() => {
    if (initRef.current) return
    initRef.current = true

    if (getUser()) {
      init(getUser().token)
    }
  })

  const init = async (token) => {
    const url = new URL(`${API_URL}/`)
    const params = {
      admin: "1",
      f: "adminDonationReportsInit",
      token,
      x: uuidv4()
    }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setItems(json.items)
        setAlert({})
      } else {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors([])
    setItems([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/?f=adminDonationReports&admin=1`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      data.append(key, value)
    })
    data.append("token", getUser().token || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setItems(json.items)
        setMsg({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Layout>
      <Helmet />
      <Container>
        {alert.type ? (
          <Alert data={alert} />
        ) : (
          <>
            <Nav
              sections={[
                { name: "Admin Home", to: "/" },
                { name: "", to: "" },
                { name: "Donation Reports", to: "" },
              ]}
              title="Select Charity"
            />

            <p className="no-top-margin">
              Enter the first few characters of the charity’s name below to
              create a donation report for a specific charity or select all
              charities report.
            </p>

            <p className="highlight">
              IMPORTANT NOTE: the report created here will include payment data,
              if a report is desired to distribute to the Charity, please use
              the public page.
            </p>

            <Grid>
              <form onSubmit={(e) => handleSubmit(e)}>
                <Input
                  type="text"
                  label="Charity Name"
                  name="str"
                  value={names.str}
                  update={handleUpdate}
                  errors={errors}
                />

                {msg.type && <Msg data={msg} />}

                {msg.type !== "working" && (
                  <Submit label="Search for Charity" />
                )}
              </form>

              <Table items={items} />
            </Grid>
          </>
        )}
      </Container>
    </Layout>
  )
}

export default Donations
