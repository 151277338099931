import React from "react"

import { Link } from "gatsby"

import Msg from "../../form/Msg/index"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleRight,
  faExternalLink,
} from "@fortawesome/pro-light-svg-icons"

const Table = ({ items }) => {
  return (
    <table>
      <thead>
        <tr>
          <th className="left">Charity</th>
          <th>Donations</th>
          <th style={{ width: "10%" }}>THC</th>
          <th>Charity</th>
        </tr>
      </thead>
      {items.length === 0 ? (
        <tbody>
          <tr>
            <td colSpan="4">
              <Msg data={{ type: "error", text: "No items to display" }} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {items.map(item => {
            return (
              <tr key={item.id}>
                <td>{item.charity}</td>
                <td className="center">{item.number}</td>
                <td className="center">
                  <Link to={`/donation-report/${item.id}`}>
                    <FontAwesomeIcon icon={faChevronCircleRight} size="lg" />
                  </Link>
                </td>
                <td className="center">
                  {item.code ? (
                    <a
                      href={`https://theclassiccares.org/birdies/report/?code=${item.code}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faExternalLink} size="lg" />
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      )}
    </table>
  )
}

export default Table
